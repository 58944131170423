import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';


// import p1 from 'assests/Photos/Clubs/EcoClub/2024/EnvironmentDay/1.jpg';
// import p2 from 'assests/Photos/Clubs/EcoClub/2024/EnvironmentDay/2.jpg';
// import p3 from 'assests/Photos/Clubs/EcoClub/2024/EnvironmentDay/3.jpg';
// import p4 from 'assests/Photos/Clubs/EcoClub/2024/EnvironmentDay/4.jpg';
// import p5 from 'assests/Photos/Clubs/EcoClub/2024/EnvironmentDay/5.jpg';
// import p6 from 'assests/Photos/Clubs/EcoClub/2024/EnvironmentDay/6.jpg';
// import p7 from 'assests/Photos/Clubs/EcoClub/2024/EnvironmentDay/7.jpg';
// import p8 from 'assests/Photos/Clubs/EcoClub/2024/EnvironmentDay/8.jpg';
// import p9 from 'assests/Photos/Clubs/EcoClub/2024/EnvironmentDay/9.jpg';
// import p10 from 'assests/Photos/Clubs/EcoClub/2024/EnvironmentDay/10.jpg';
// import p11 from 'assests/Photos/Clubs/EcoClub/2024/EnvironmentDay/11.jpg';
// import p12 from 'assests/Photos/Clubs/EcoClub/2024/EnvironmentDay/12.jpg';
// import p13 from 'assests/Photos/Clubs/EcoClub/2024/EnvironmentDay/13.jpg';
// import p14 from 'assests/Photos/Clubs/EcoClub/2024/EnvironmentDay/14.jpg';
// import p15 from 'assests/Photos/Clubs/EcoClub/2024/EnvironmentDay/15.jpg';
// import p16 from 'assests/Photos/Clubs/EcoClub/2024/EnvironmentDay/16.jpg';
// import p17 from 'assests/Photos/Clubs/EcoClub/2024/EnvironmentDay/17.jpg';
// import p18 from 'assests/Photos/Clubs/EcoClub/2024/EnvironmentDay/18.jpg';


import Ecoclub2023 from 'views/EnvironmentDay2023/Ecoclub2023';

import SideHealth from 'views/HealthAndWellnesClub/SideHealth';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import EcoClubSidebar2024 from '../EnvironmentDay2023/EcoClubSidebar2024';
import Sidebar from '../EcoClub/Sidebar';


const EnvironmentDay2024 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });


  const [config, setConfig] = useState({ base_image_url: '' });
  useEffect(() => {
    fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const p1 = `${config.base_image_url}/home/events-activities/association/EcoClub/EnvironmentDay/1.webp`;
  const p2 = `${config.base_image_url}/home/events-activities/association/EcoClub/EnvironmentDay/2.webp`;
  const p3 = `${config.base_image_url}/home/events-activities/association/EcoClub/EnvironmentDay/3.webp`;
  const p4 = `${config.base_image_url}/home/events-activities/association/EcoClub/EnvironmentDay/4.webp`;
  const p5 = `${config.base_image_url}/home/events-activities/association/EcoClub/EnvironmentDay/5.webp`;
  const p6 = `${config.base_image_url}/home/events-activities/association/EcoClub/EnvironmentDay/6.webp`;
  const p7 = `${config.base_image_url}/home/events-activities/association/EcoClub/EnvironmentDay/7.webp`;
  const p8 = `${config.base_image_url}/home/events-activities/association/EcoClub/EnvironmentDay/8.webp`;
  const p9 = `${config.base_image_url}/home/events-activities/association/EcoClub/EnvironmentDay/9.webp`;
  const p10 = `${config.base_image_url}/home/events-activities/association/EcoClub/EnvironmentDay/10.webp`;
  const p11 = `${config.base_image_url}/home/events-activities/association/EcoClub/EnvironmentDay/11.webp`;
  const p12 = `${config.base_image_url}/home/events-activities/association/EcoClub/EnvironmentDay/12.webp`;
  const p13 = `${config.base_image_url}/home/events-activities/association/EcoClub/EnvironmentDay/13.webp`;
  const p14 = `${config.base_image_url}/home/events-activities/association/EcoClub/EnvironmentDay/14.webp`;
  const p15 = `${config.base_image_url}/home/events-activities/association/EcoClub/EnvironmentDay/15.webp`;
  const p16 = `${config.base_image_url}/home/events-activities/association/EcoClub/EnvironmentDay/16.webp`;
  const p17 = `${config.base_image_url}/home/events-activities/association/EcoClub/EnvironmentDay/17.webp`;
  const p18 = `${config.base_image_url}/home/events-activities/association/EcoClub/EnvironmentDay/18.webp`;
  

  const photos = [
    {
        src: p1,
        source: p1,
        rows: 1.2,
        cols: 2,
    },
    {
        src: p2,
        source: p2,
        rows: 1,
        cols: 1,
    },
    {
        src: p3,
        source: p3,
        rows: 1,
        cols: 1,
    },
    {
        src: p4,
        source: p4,
        rows: 1,
        cols: 1,
    }, {
        src: p5,
        source: p5,
        rows: 1,
        cols: 1,
    },
    {
        src: p6,
        source: p6,
        rows: 2,
        cols: 1,
    }, 
    {
        src: p7,
        source: p7,
        rows: 1,
        cols: 1,
    },
    {
        src: p8,
        source: p8,
        rows: 1,
        cols: 1,
    },
    {
        src: p9,
        source: p9,
        rows: 1,
        cols: 1,
    },
    {
      src: p10,
      source: p10,
      rows: 1,
      cols: 1,
    }, 
    {
    src: p11,
    source: p11,
    rows: 1,
    cols: 1,
   },
   {
    src: p12,
    source: p12,
    rows: 2,
    cols: 1,
   },
   {
    src: p13,
    source: p13,
    rows: 1,
    cols: 1,
   },
   {
    src: p14,
    source: p14,
    rows: 1.3,
    cols: 2,
   }, 
   {
    src: p15,
    source: p15,
    rows: 1.3,
    cols: 2,
   },
   {
    src: p16,
    source: p16,
    rows: 1.3,
    cols: 2,
   }, 
   {
    src: p17,
    source: p17,
    rows: 1,
    cols: 1,
    },

      {
        src: p18,
        source:p18,
        rows: 1,
        cols: 1,
      },
    
  ];

  return (
    <Main>
   
<Container>

  <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center' >
      Environment Day
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
      Class: PRE-PRIMARY  &  Date: 28 June 2024
        </Typography>
        <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
"Time spent among trees is never time wasted. Let’s embark on a Journey into Nature"

        <br/>
        </Typography>
        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        The Environment Day celebration conducted for Pre-Primary students on Friday, 28 June 2024 was a resounding success, immersing young minds in the wonders of nature through engaging and educational activities. The event was tailored for children for Classes Montessori-I, II, III and Pre-K, KG-I and KG-II. Each group took part in unique hands-on experiences that fostered a deeper connection with the environment.
        <br></br>
        <strong>Sensory Garden for Montessori-I and Pre-K</strong>
        <br></br>
        Our little gardeners from Montessori-I & Pre-K were introduced to the sensory garden, an enchanting space designed to stimulate their senses and curiosity. Here, they could visually appreciate the vibrant colors of flowering plants, feel the diverse textures of leaves and inhale the aromatic scents of various herbs such as marjoram, citronella, rosemary and oregano. This activity not only delighted their senses but also provided a tactile learning experience, helping them to understand and appreciate the beauty and diversity of plant life.
        <br></br>
        <strong>Aqua Garden for Montessori-II and KG-I</strong> 
        <br></br>
        The aqualogists from Montessori-II and KG-I embarked on a fascinating journey in the aqua garden. They learnt how lotus plants grow from seeds, witnessing firsthand the intricate process involved. Additionally, they explored the differences in root systems between water plants and soil plants, using water lettuce plants as a practical example. This activity enriched their understanding of plant biology and the unique adaptations of aquatic plants, sparking a sense of wonder and curiosity about the natural world.
        <br></br>
        <strong>Propagation Activities for Montessori-III and KG-II</strong>
        <br></br>
        Our green brigadiers delved into the fascinating world of plant propagation. Through hands-on activities, they learnt how plants can be grown from leaves, stems and shoots, discovering that seeds are not always necessary for plant growth. This experience not only taught them about the various methods of plant propagation but also encouraged them to develop a hobby and a lifelong connection with nature. By understanding these propagation techniques, the children gained valuable insights into the resilience and diversity of plant life.
        <br></br>
        The Environment Day celebration was a memorable and enriching experience for our pre-primary students. Each activity was carefully crafted to suit their developmental stages, ensuring that they learned through play and exploration. By fostering an early appreciation for nature, we hope to inspire the next generation to care for and protect our planet.
        <br></br>
        As Rachel Carson once said, "In nature, nothing exists alone." This quote encapsulates the essence of our Environment Day celebration, reminding us all of the interconnectedness of life and the importance of nurturing a bond with the natural world from an early age.
       
        </Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
"We have learned that we are never too small to make a difference"

<br/>
        </Typography>

      <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>

    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <EcoClubSidebar2024 />
             </Box>
             <Box marginBottom={4}>
               <Sidebar />
             </Box>


    </Grid>
    </Grid> 
    </Container>
    </Main>
   
   
  );
};

export default EnvironmentDay2024;